import Link from "next/link";
import React, { createContext, useContext, useEffect, useState } from "react";
import { PageProps } from "../types";
import { NavigationItem, NavigationItemWithParent } from "../types/navigation";
import { SiteSettings } from "../types/SiteSettings";
import TextUtil from "../util/TextUtil";
import { BaseComponent } from "../types/components";
import {
  EventSearchItem,
  FrontendPageData,
  NavigationItemWithTranslations,
  SiteLanguage,
} from "@messe-sp/data/types";
import Cookies from "js-cookie";
import { useUpdateEffect } from "react-use";

export type SpSiteData = {
  baseUrl: string;
  apiUrl: string;
  spName: string;
  defaultLanguage: string;
  isProduction?: boolean;
};

type ContextValue = {
  primaryLanguage: string;
  languages: SiteLanguage[];
  currentLanguage: string;
  specialPages: Record<string, NavigationItemWithTranslations>;
  page: FrontendPageData;
  getSpecialLink: (specialPage: string) =>
    | {
        component: React.ReactElement;
        href: string;
      }
    | undefined;
  navigation: NavigationItemWithTranslations[];
  getLocalizedLinkForPage: (
    currentPage: FrontendPageData,
    newLanguage: string
  ) => string;
  getLocalizedLink: (slug: string, lang?: string) => string;
  t: (translationKey: string) => string;
  cubes: {
    big: Record<string, string>;
    small: Record<string, string>;
  };
  getRelativeOrAbsoluteUrl: (link: string) => {
    isExternal: boolean;
    href: string;
  };
  getAbsoluteUrl: (
    language: string,
    page: FrontendPageData
  ) => string | undefined;

  social?: Record<string, string>;
  baseUrl: string;
  textUtil: ReturnType<typeof TextUtil>;
  eventClusters: string[];
  eventApiUrl: string;
  quicklinks: Partial<SiteSettings["quicklinks"]>;
  cookiesAllowed: boolean | null;
  setCookiesAccepted: (accepted: boolean) => void;
  userDefinedLanguage: string;
  setUserDefinedLanguage: (langShort: string) => void;
  companyName?: string;
  eventSettings: SiteSettings["eventSettings"];
  spName: string;
  isProduction?: boolean;
  cmpId?: string;
  consentBannerLanguage?: string;
};

type Props = PageProps; /*Shared & {
    specialPages: Record<string, SpecialPages>;
    menu: NavigationItem[];
    siteSettings: SiteSettings;
};*/

export const SiteDataContext = createContext<ContextValue | null>(null);

const buildNavigation = (
  menu: NavigationItem[],
  parent?: NavigationItemWithParent
): NavigationItemWithParent[] => {
  return menu.map((item) => {
    const transformed: NavigationItemWithParent = {
      ...item,
      parent,
      children: undefined,
    };

    if (item.children?.length)
      transformed.children = buildNavigation(item.children, transformed);

    return transformed;
  });
};

const rInternalLink = /^(?:https?:\/\/[^\/]*)?(\/.*)$/im;

const checkCookies = () => {
  const cookieValue = Cookies.get("cookies-accepted");
  if (cookieValue) {
    return cookieValue.toString() === "true";
  } else {
    return null;
  }
};

const saveCookiesAllowed = (allowed = true) => {
  Cookies.set("cookies-accepted", allowed.toString(), { expires: 365 });
};

const checkUserDefinedLanguage = () => {
  return Cookies.get("sp-lang");
};

const saveUserDefinedLanguage = (langShort: string) => {
  Cookies.set("sp-lang", langShort, { expires: 365 });
};

export const SiteDataProvider: BaseComponent<Props> = ({
  children,
  page,
  site,
  specialPages,
  language,
  menu,
  baseUrl,
  apiUrl,
  isProduction,
}) => {
  const [cookiesAllowed, setCookiesAllowed] = useState(() => checkCookies());
  const [userDefinedLanguage, setUserDefinedLanguage] = useState(
    () => checkUserDefinedLanguage() || language
  );
  const { spName } = site;

  const baseValue = {
    siteUrls: ["localhost:\\d*", ...site.domains.map((it) => it.domain)],
    spName,
    baseUrl: `${baseUrl}/${language}`,
    baseUrlNoLang: baseUrl,
    apiUrl: baseUrl,
    isProduction,
    eventApiUrl:
      process.env.NEXT_PUBLIC_EVENT_API_URL || typeof window !== "undefined"
        ? `/api`
        : `${baseUrl}/api`,
  };

  useUpdateEffect(() => {
    if (userDefinedLanguage && cookiesAllowed)
      saveUserDefinedLanguage(userDefinedLanguage);
  }, [userDefinedLanguage, cookiesAllowed]);

  useEffect(() => {
    if (cookiesAllowed) {
      saveCookiesAllowed();
    }
  }, [cookiesAllowed]);
  const providerValue: ContextValue = {
    ...baseValue,
    consentBannerLanguage: site.languages?.find((it) => it.short === language)
      ?.consentBannerLanguage,
    primaryLanguage: site.defaultLanguage,
    languages: site.languages,
    currentLanguage: language,
    textUtil: TextUtil(baseValue),
    eventClusters: site.eventClusters,
    cookiesAllowed,
    userDefinedLanguage,
    cmpId: site.siteSettings?.globals?.cmpId,
    eventSettings: site.siteSettings?.eventSettings,
    setUserDefinedLanguage: (langShort: string) => {
      setUserDefinedLanguage(langShort);
    },
    setCookiesAccepted: (accepted) => {
      setCookiesAllowed(accepted);
    },
    specialPages,

    getAbsoluteUrl: (lang, p) => {
      if (p) {
        return providerValue.getLocalizedLink(p.slug, lang);
      } else {
        return undefined;
      }
    },
    getRelativeOrAbsoluteUrl: (link: string) => {
      const isExternal =
        link.startsWith("http") &&
        !baseValue.siteUrls.find((it) => link.includes(it));
      if (isExternal) {
        return {
          isExternal: true,
          href: link,
        };
      } else {
        const match = link.match(rInternalLink);
        if (match) {
          const t = match[1];
          return {
            isExternal: false,
            href: t,
          };
        } else {
          return {
            isExternal: false,
            href: link,
          };
        }
      }
    },
    getSpecialLink: (pageType) => {
      const specialPage = specialPages[pageType];

      if (specialPage) {
        const externalNewsletter = site?.siteSettings?.globals
          ?.externalNewsletter as string;

        if (pageType === "Newsletter" && externalNewsletter) {
          return {
            component: (
              <Link href={externalNewsletter}>
                <a
                  dangerouslySetInnerHTML={{
                    __html: specialPage.pageTitle,
                  }}
                />
              </Link>
            ),
            href: externalNewsletter,
          };
        }

        const href = `/${language}/${specialPage.slug}`;
        return {
          component: (
            <Link href={href}>
              <a
                dangerouslySetInnerHTML={{
                  __html: specialPage.pageTitle,
                }}
              />
            </Link>
          ),
          href,
        };
      } else {
        return undefined;
      }
    },
    t: (translationKey) => {
      return (
        site.siteSettings.translations?.[language]?.[translationKey] ||
        translationKey
      );
    },
    page,

    navigation: menu,
    getLocalizedLink: (slug, lang = language) => {
      return `${baseUrl}/${lang}/${slug}`;
    },
    getLocalizedLinkForPage: (nextPage, nextLang) => {
      return `${baseUrl}/${nextLang}/${nextPage.translatedSlugs[nextLang]}`;
    },
    social: site.siteSettings.social,
    companyName: site.siteSettings.globals?.companyName,
    cubes: site.cubes,
    quicklinks: site.quicklinks!,
  };
  useEffect(() => {
    console.log("baseUrl", baseUrl);
  }, [baseUrl]);

  if (typeof window === "undefined") {
    console.log("baseUrl", baseUrl);
  }

  return (
    <SiteDataContext.Provider value={providerValue}>
      {children}
      {site?.siteSettings?.customHtml && (
        <div
          dangerouslySetInnerHTML={{
            __html: site.siteSettings.customHtml,
          }}
        />
      )}
    </SiteDataContext.Provider>
  );
};

export const useSiteData = (): ContextValue => {
  const context = useContext(SiteDataContext);

  if (!context) {
    throw new Error("useSiteData must be used within SiteDataProvider");
  }

  return context;
};
